@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: GTWalsheimPro;
  src: url('/fonts/GTWalsheimPro-Bold.ttf');
}

@font-face {
  font-family: Avenir;
  src: url('/fonts/avenir.ttf');
}
@font-face {
  font-family: LondrinaSolid;
  src: url('/fonts/LondrinaSolid-Black.ttf');
}
@font-face {
  font-family: LexendDeca;
  src: url('/fonts/LexendDeca-Medium.ttf');
}

@layer base {
  html {
    @apply scroll-smooth;
  }
}

.breadcrumb-item {
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 3.6em;
  line-height: 1.8em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
